<template>
  <span class="round">{{str}}</span>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Round',
  props: {
    type: { type: String, required: true },
    round: { type: Number, required: true }
  },
  computed: {
    str () {
      const day = dayjs(this.round)
      switch (this.type) {
        case 'month':
          return day.format('YYYY年MM月')
        case 'season':
          return day.format('YYYY年') + ((day.month() + 1) / 3) + '季度'
        case 'halfyear':
          return day.format('YYYY年') + (day.month() === 5 ? '上半年' : '下半年')
        case 'year':
          return day.format('YYYY年')
        default:
          return '未定义'
      }
    }
  }
}
</script>
