import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import myProgress from './modules/my_progress'
import progress from './modules/progress'
import records from './modules/records'
import samples from './modules/samples'
import users from './modules/users'
import rounds from './modules/rounds'
import tables from './modules/tables'
import sample from './modules/sample'
import indexSystem from './modules/index_system'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    progress,
    myProgress,
    records,
    samples,
    users,
    rounds,
    tables,
    sample,
    indexSystem
  }
})
