<style lang="scss" scoped>
  .info {
    padding: 0 30px 30px;
    .table { margin-top: 20px; }
    h3 { font-size: 18px; text-align: center; margin-bottom: 10px;}
    .status { text-align: center; margin-top: 10px; margin-bottom: 10px; span { margin-right: 10px; &:last-child {margin-right: 0; } }  }
    .act { margin-top: 10px; text-align: right;}
    .time { margin-top: 15px; text-align: right; color: #888; }
  }
</style>
<template>
  <el-drawer
    title="查看报表"
    :visible.sync="visible"
    direction="rtl"
    size="70%"
    v-loading="true"
  >
  <loading-content :loading="loading" :error="error" customRetry @retry="requestData">
    <div v-if="info" class="info">
      <!-- <h3>{{info.table.name}}</h3>
      <div class="status">
        <round :round="info.round.round" :type="info.round.type" />
        <span class="sample" v-if="user.role === 'admin'">{{info.sample.name}}</span> <review-status :status="info.reviewStatus" />
        <div v-if="info.reviewComment" style="color: #616161; font-size: 12px; margin-top: 5px;" ><span>审核说明：</span>{{info.reviewComment}}</div>
      </div>
      <div class="act" v-if="user.role === 'admin'" style="margin-bottom: 10px;">
        <el-button v-if="info.reviewStatus !== 0" size="mini" type="warning" round @click="postReview(0)">重置为未审核</el-button>
        <el-button v-if="info.reviewStatus !== 1" size="mini" type="success" round @click="postReview(1)">通过审核</el-button>
        <el-button v-if="info.reviewStatus !== -1" size="mini" type="warning" round @click="postReview(-1)">不通过审核</el-button>
      </div> -->
      <report-table :table="info.table" :round="info.round" :report="info.report" :reporting="false" :user="info.user" :sample="info.sample" @postReview="postReview"></report-table>
      <!-- <div class="time">
        <span>填报用户：</span><span>{{info.user.name}}</span><span style="margin-left:15px;">电话：</span><span>{{info.user.phone}}</span>
      </div>
      <div v-if="info.updateAt && info.updateAt !== info.createAt" class="time">
        <span>首次填报：</span><date-time :time="info.createAt" showTime></date-time>
        <span style="margin-left: 10px">上次修改：</span><date-time :time="info.updateAt" showTime></date-time>
      </div>
      <div v-else-if="info.createAt" class="time">
        <span>填报时间：</span><date-time :time="info.createAt" showTime></date-time>
      </div> -->
    </div>
  </loading-content>
</el-drawer>
</template>

<script>
import { mapState } from 'vuex'
// import request from '../request'
// import ReviewStatus from './ReviewStatus.vue'

export default {
  // components: { ReviewStatus },
  name: 'ReportView',
  data () {
    return {
      visible: false,
      reportId: 0,
      info: null,
      loading: true,
      error: null,
      table: ''
    }
  },
  computed: {
    ...mapState('account', ['user'])
  },
  watch: {
    visible (v) {
      if (!v) return
      this.loading = true
      this.requestData()
    }
  },
  methods: {
    requestData () {
      this.$request('/reports/' + this.reportId)
        .then(res => {
          this.loading = false
          this.info = res
        }).catch(err => {
          this.loading = false
          this.error = err
        })
    },
    postReview (status) {
      const title = status === 1 ? '确定【通过审核】吗？' : status === -1 ? '确定【审核不通过】吗？' : status === 0 ? '确定【重置为未审核】吗？' : '确定？'
      this.$prompt('请确认', title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showInput: true,
        inputType: 'text',
        inputPlaceholder: '如果有说明，请输入（可选）'
      }).then(({ value }) => {
        this.$request.post(`/reports/${this.reportId}/review`, { comment: value || '', status })
          .then(() => {
            const name = this.$route.name
            if (name === 'records' || name === 'progress') {
              this.$store.dispatch(`${name}/reGet`)
            }
            this.requestData()
          })
      })
    }
  }
}
</script>
