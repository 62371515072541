<template>
  <span>
    <el-tag size="small" :type="color" class="review-status" v-if="!comment">{{str}}</el-tag>
    <el-tooltip class="item" effect="dark" :content="comment" placement="top" v-else>
      <el-tag size="small" :type="color" class="review-status">{{str}} <i class="el-icon-question"></i></el-tag>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  name: 'ReviewStatus',
  props: {
    type: { type: String, default: 'label' },
    status: { type: Number, required: false, default: 0 },
    comment: { type: String }
  },
  computed: {
    color () {
      switch (this.status) {
        case 1:
          return 'success'
        case -1:
          return 'danger'
        case 0:
          return 'warning'
        default:
          return 'info'
      }
    },
    str () {
      switch (this.status) {
        case 1:
          return '已通过审核'
        case -1:
          return '审核未通过'
        case 0:
          return '未审核'
        default:
          return '未审核'
      }
    }
  }
}
</script>
