<template>
  <span class="sample-status">
    <span v-if="type === 'string'">{{str}}</span>
    <el-tag size="small" v-else-if="type === 'label'" :type="color">{{str}}</el-tag>
  </span>
</template>

<script>
export default {
  name: 'SampleStatus',
  props: {
    type: { type: String, default: 'string' },
    status: { type: Number, required: false, default: 0 }
  },
  computed: {
    color () {
      switch (this.status) {
        case 1:
          return 'success'
        case -1:
          return 'danger'
        case 0:
          return 'warning'
        default:
          return 'info'
      }
    },
    str () {
      switch (this.status) {
        case 1:
          return '正常'
        case -1:
          return '已禁用'
        case 0:
          return '待确认'
        default:
          return '未定义'
      }
    }
  }
}
</script>
