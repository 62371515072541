import request from '@/request'

export default {
  namespaced: true,
  state: {
    user: null,
    error: null,
    requested: false
  },
  mutations: {
    doneLoginError (state, error) {
      state.error = error
    },
    doneLogin (state, payload) {
      state.user = payload
    }
  },
  actions: {
    getCurrentUser ({ commit }) {
      return new Promise((resolve, reject) => {
        request.get('account/current')
          .then((user) => {
            commit('doneLogin', user)
            resolve(user)
          })
          .catch((err) => {
            commit('doneLoginError', err)
            reject(err)
          })
      })
    },
    login ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/login', data).then(res => {
          dispatch('getCurrentUser')
          resolve()
        }).catch(reject)
      })
    },
    register ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/register', data).then(res => {
          dispatch('getCurrentUser')
          resolve()
        }).catch(reject)
      })
    },
    upsertSample ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/sample', data).then(res => {
          dispatch('getCurrentUser')
          resolve()
        }).catch(reject)
      })
    },
    changePhone ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/phone', data).then(res => {
          dispatch('getCurrentUser')
          resolve()
        }).catch(reject)
      })
    },
    changePassword (_, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/password', data).then(res => {
          resolve()
        }).catch(reject)
      })
    },
    updateProfile ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/account/profile', data).then(res => {
          resolve()
          dispatch('getCurrentUser')
        }).catch(reject)
      })
    }
  }
}
