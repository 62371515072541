<template>
  <span class="status">
    <el-tag v-if="!comment" size="small" :type="color">{{str}}</el-tag>
    <el-tooltip class="item" effect="dark" :content="comment" placement="top" v-else>
      <el-tag size="small" :type="color" class="review-status">{{str}} <i class="el-icon-question"></i></el-tag>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  name: 'ReportStatus',
  props: {
    type: { type: String, default: 'label' },
    report: { type: Number, required: false, default: 0 },
    review: { type: Number, required: false, default: 0 },
    comment: { type: String }
  },
  computed: {
    color () {
      if (this.report === null || this.report === undefined) return 'warning'
      if (this.report === 1) {
        switch (this.review) {
          case 1:
            return 'success'
          case -1:
            return 'danger'
          case 0:
            return 'info'
          default:
            return 'info'
        }
      }
      return 'info'
    },
    str () {
      if (this.report === null || this.report === undefined) return '未填报'
      if (this.report === 1) {
        switch (this.review) {
          case 1:
            return '已审核通过'
          case -1:
            return '审核未通过'
          case 0:
            return '已填报待审核'
          default:
            return '未定义'
        }
      }
      return '未定义'
    }
  }
}
</script>
