<style lang="scss" scoped>
  .title {
    font-size: 24px; padding: 0 0 30px 140px;
  }
</style>
<template>
<div style="width: 372px; padding: 40px 40px 0px 20px;">
  <div class="title">填报负责人变更</div>
  <el-form ref="form" :model="form" :rules="rules" size="small" hide-required-asterisk @submit.native.prevent="doLogin" label-width="140px">
    <el-form-item label="原负责人手机号码" prop="phone1">
      <el-input placeholder="请输入原负责人手机号码"  v-model="form.phone1" clearable></el-input>
    </el-form-item>
    <el-form-item label="原负责人验证码" prop="code1">
      <el-input v-model="form.code1" placeholder="验证码" style="width: 110px; margin-right: 8px;"></el-input>
      <code-button :phone="form.phone1" />
    </el-form-item>
    <el-form-item label="新负责人手机号码" prop="phone">
      <el-input placeholder="请输入新负责人手机号码"  v-model="form.phone" clearable></el-input>
    </el-form-item>
    <el-form-item label="新负责人验证码" prop="code">
      <el-input v-model="form.code" placeholder="验证码" style="width: 110px; margin-right: 8px;"></el-input>
      <code-button :phone="form.phone" />
    </el-form-item>
    <el-form-item label="新负责人姓名" prop="name">
      <el-input placeholder="请输入新负责人姓名"  v-model="form.name" clearable></el-input>
    </el-form-item>
    <el-form-item label="新负责人登录密码" prop="password">
      <el-input placeholder="请输入新负责人登录密码" v-model="form.password" show-password></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting">确认变更</el-button>
      <div style="text-align: right;">
        <el-link href="#/account/login">返回登录</el-link>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      form: { username: '', password: '' },
      rules: {
        phone1: [
          { required: true, message: '请输入原负责人手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        code1: [
          { required: true, message: '请输入验证码' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名' },
          { max: 50, message: '长度不能超过50', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      posting: false
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    doLogin () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        this.$request.post('/account/change_fzr', { ...this.form })
          .then(res => {
            this.$message({ type: 'success', message: '变更成功，请登录' })
            this.posting = false
            setTimeout(() => {
              this.$router.replace('/account/login')
            }, 500)
          }).catch(() => {
            this.posting = false
          })
      })
    }
  }
}
</script>
