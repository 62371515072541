<template>
<el-container style="height: 100%; min-height: 100%;">
  <el-main v-if="loading">
  </el-main>
  <el-main v-else-if="error" class="main-error">
    <embed :src="svg" type="image/svg+xml" class="img" />
    <p class="message">{{error.message}}</p>
    <div class="btn"><el-button type="primary" round icon="el-icon-refresh" @click="reload">点此刷新</el-button></div>
  </el-main>
  <template v-else>
    <el-aside width="200px" class="side">
      <!-- <div>
        <img :src="navImg" style="width: 100%;" />
      </div> -->
      <el-menu v-if="user.role === 'sample'" router>
        <el-menu-item index="/">
          <i class="el-icon-menu"></i>
          <span slot="title">统计上报</span>
        </el-menu-item>
        <el-menu-item index="/records">
          <i class="el-icon-document-copy"></i>
          <span slot="title">填报记录</span>
        </el-menu-item>
        <el-menu-item index="/info/zhidu">
          <i class="el-icon-notebook-1"></i>
          <span slot="title">报表制度</span>
        </el-menu-item>
        <el-menu-item index="/info/zhibiao">
          <i class="el-icon-guide"></i>
          <span slot="title">指标解释</span>
        </el-menu-item>
        <el-menu-item index="/i/profile">
          <i class="el-icon-user"></i>
          <span slot="title">修改资料</span>
        </el-menu-item>
        <el-menu-item index="/i/password">
          <i class="el-icon-setting"></i>
          <span slot="title">系统管理</span>
        </el-menu-item>
      </el-menu>
      <el-menu v-else router>
        <el-menu-item index="/">
          <i class="el-icon-menu"></i>
          <span slot="title">管理台</span>
        </el-menu-item>
        <el-menu-item index="/progress">
          <i class="el-icon-time"></i>
          <span slot="title">填报进度</span>
        </el-menu-item>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-files"></i>
            <span>填报记录</span>
          </template>
          <el-menu-item index="/records/data">
            <span slot="title">数据查询</span>
          </el-menu-item>
          <el-menu-item index="/records">
            <span slot="title">历史记录</span>
          </el-menu-item>
          <el-menu-item index="/records/samples">
            <span slot="title">填报统计</span>
          </el-menu-item>
        </el-submenu>
        <!--  <el-menu-item index="/aggregation" v-if="user.role === 'admin'">
          <i class="el-icon-pie-chart"></i>
          <span slot="title">数据汇总</span>
        </el-menu-item> -->
        <el-submenu index="indexes">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>指数查询</span>
          </template>
          <el-menu-item index="/indexes/chart">
            <span slot="title">图表展示</span>
          </el-menu-item>
          <el-menu-item index="/indexes/data">
            <span slot="title">指数数据</span>
          </el-menu-item>
          <el-menu-item index="/indexes/jingqi">
            <span slot="title">景气分析</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-mouse"></i>
            <span>指数计算</span>
          </template>
          <el-menu-item index="/index_system/calculate">
            <span slot="title">指标计算</span>
          </el-menu-item>
          <el-menu-item index="/index_system/report">
            <span slot="title">指标填报</span>
          </el-menu-item>
          <el-menu-item index="/index_system/records">
            <span slot="title">填报查询</span>
          </el-menu-item>
          <el-menu-item index="/index_system/system">
            <span slot="title">指标体系</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/samples">
          <i class="el-icon-office-building"></i>
          <span slot="title">样本管理</span>
        </el-menu-item>
        <el-submenu index="/movies">
          <template slot="title">
            <i class="el-icon-camera"></i>
            <span slot="title">影视管理</span>
          </template>
          <el-menu-item index="/movies/list">
            <span slot="title">所有影视</span>
          </el-menu-item>
          <!-- <el-menu-item index="/movies/create">
            <span slot="title">添加影视</span>
          </el-menu-item> -->
          <el-menu-item index="/movies/company">
            <span slot="title">影视公司</span>
          </el-menu-item>
          <el-menu-item index="/movies/data">
            <span slot="title">爬取数据</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/users">
          <i class="el-icon-user"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>
        <el-menu-item index="/rounds" v-if="user.role === 'admin'">
          <i class="el-icon-date"></i>
          <span slot="title">期次管理</span>
        </el-menu-item>
        <el-menu-item index="/i/profile">
          <i class="el-icon-set-up"></i>
          <span slot="title">修改资料</span>
        </el-menu-item>
        <el-menu-item index="/i/password">
          <i class="el-icon-setting"></i>
          <span slot="title">系统管理</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header height="40px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(crumb, index) in crumbs" :key="crumb.path" :to="crumb.path ? {path: crumb.path} : null"><i class="el-icon-s-home" v-if="index === 0" style="margin-right: 3px; font-size: 16px;"></i>{{crumb.name}}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div class="qq">
          <el-popover
            placement="bottom"
            width="260"
            trigger="hover">
            <div class="qq-gp">
              <div class="link">
                <span>群号：707440900</span>
                <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=lQTk2sGENSjbQW3EJ-UulY1vGkQD1NJ8&jump_from=webapi"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="杭州新零售统计调查" title="杭州新零售统计调查"></a>
              </div>
              <el-divider></el-divider>
              <div class="qr">
                <span>手机QQ扫一扫</span>
                <img :src="qqImg" />
              </div>
            </div>
            <span slot="reference">加入QQ群</span>
          </el-popover>
        </div> -->
        <el-dropdown @command="tapUser">
          <span class="el-dropdown-link" style="cursor: pointer;">您好 ~ {{user.name}} <i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="phone">{{user.phone || '未绑定手机号码'}}</el-dropdown-item>
            <el-dropdown-item icon="el-icon-user" command="profile" divided>修改资料</el-dropdown-item>
            <el-dropdown-item icon="el-icon-set-up" command="password">修改密码</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" command="logout" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main style="height: 0; min-height: 0;">
        <router-view />
      </el-main>
    </el-container>
  </template>
</el-container>
</template>
<script>
import request from '../request'
import svg from '../assets/error.svg'
import qqImg from '../assets/qq.png'
import navImg from '../assets/nav.jpeg'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MainLayOut',
  data () {
    return {
      loading: true,
      crumbs: [],
      svg,
      qqImg,
      navImg
    }
  },
  computed: {
    ...mapState('account', ['user']),
    error: function () {
      return this.$store.state.account.error || this.$store.state.tables.error || this.$store.state.indexSystem.error
    }
  },
  watch: {
    $route (v) {
      this.setBreadcrumb()
    }
  },
  mounted () {
    this.setBreadcrumb()
    const all = this.user.role === 'admin' ? Promise.all([this.fetch(), this.fetchIndexSystem()]) : Promise.all([this.fetch()])
    all.then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('tables', ['fetch']),
    ...mapActions('indexSystem', ['fetchIndexSystem']),
    setBreadcrumb () {
      if (this.$route.path === '/home') {
        this.crumbs = [{ name: '首页', path: '/home' }]
      } else {
        const last = this.$route.matched.length - 1
        this.crumbs = this.$route.matched.map((v, index) => ({
          name: v.meta.title,
          path: last !== index ? v.path || '/home' : null
        }))
      }
    },
    tapUser (command) {
      if (command === 'profile' || command === 'password') {
        this.$router.push('/i/' + command)
      } else if (command === 'logout') {
        request.post('/account/logout')
          .then(() => {
            window.location.reload(true)
          })
      }
    },
    reload () {
      window.location.reload(true)
    }
  }

}
</script>
<style lang="scss" scoped>
  .el-header {
    display: flex; line-height: 40px; font-size: 12px; background: #fff; border-bottom: 1px solid #E4E7ED;
    .el-breadcrumb { flex: 1; line-height: 40px; }
    // .el-dropdown {}
  }
  .qq  { margin-right: 20px; cursor: pointer; color: #606266; font-size: 13px; }
  .qq-gp {
      text-align: center; padding-top: 8px;
      .link {
        span { margin-right: 10px; }
        span, a { display: inline-block; line-height: 22px; vertical-align: top;}
      }
      .qr { img {width: 100%;}}
    }
  .side {
    &, .el-menu {background: #192a5e;}
    .el-menu { border-right: none !important; }
    .el-menu-item {
      color: #cacfd8; background-color: rgb(25, 42, 94);
      &:hover { background-color: #0a1949;}
      &.is-active, &.is-active .iconfont {
        color: #3963bc !important;
      }
    }
    ::v-deep.el-submenu__title {
      color: #cacfd8 !important; background-color: rgb(25, 42, 94);
      &:hover { background-color: #0a1949;}
    }
  }
  .main-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .img { width: 250px; margin-bottom: 20px; }
  .message { color: #999; margin-bottom: 20px; }
</style>
