<style lang="scss" scoped>
::v-deep .el-form-item--mini.el-form-item { margin-bottom: 0 !important;}
</style>
<template>
  <div>
    <el-form-item v-if="reporting" :prop="code" :rules="extraRule ? [{required: true, message: input.inputType === 'select' ? '请选择' : '请输入'}, extraRule] : [{required: true, message: input.inputType === 'select' ? '请选择' : '请输入'}]">
      <!-- <el-select v-if="input.inputType === 'select'" v-model="form[code]"  size="mini" placeholder="请选择">
        <el-option
          v-for="(value, key) in input.options"
          :key="key"
          :label="value"
          :value="key">
        </el-option>
      </el-select> -->
      <el-radio-group v-if="input.inputType === 'select'" v-model="form[code]"  size="mini">
        <el-radio v-for="(value, key) in input.options" :label="key" :key="key">{{value}}</el-radio>
      </el-radio-group>
      <el-input-number v-else-if="input.inputType === 'number'" size="small" v-model="form[code]" :controls="false" :precision="input.precision" :min="input.min"></el-input-number>
      <span v-if="input.measure" class="measure">{{input.measure}}</span>
    </el-form-item>
    <div v-else>
      <span v-if="input.inputType === 'select'">{{input.options[form[code]]}}</span>
      <span v-else>{{form[code]}}</span>
      <span v-if="input.measure" class="measure">{{input.measure}}</span>
    </div>
  </div>
</template>

<script>
// :rules="[{required: true, message: input.inputType === 'select' ? '请选择' : '请输入'}]"

export default {
  name: 'ReportTableInput',
  props: {
    reporting: { type: Boolean, required: true, default: true },
    form: { type: Object, required: true },
    input: { type: Object, required: true },
    code: { type: String, required: true },
    extraRule: { type: Object, default: undefined }
  }
}
</script>
