<template>
  <span class="date-time">{{str}}</span>
</template>

<script>
import dayjs from 'dayjs'
const today = new Date()
today.setHours(0, 0, 0, 0)

export default {
  name: 'DateTime',
  props: {
    time: { type: Number },
    showTime: { type: Boolean, default: false },
    pretty: { type: Boolean, default: true },
    format: { type: String, default: '' }
  },
  computed: {
    str () {
      if (!this.time) return ''
      const d = dayjs(this.time)
      if (this.format) return d.format(this.format)
      // const sameYear = d.year() === today.getFullYear()
      const sameYear = d.isSame(today, 'year')
      if (!this.pretty) {
        let f1 = sameYear ? 'MM月DD日' : 'YY年MM月DD日'
        if (this.showTime) f1 += ' HH:mm'
        return d.format(f1)
      }
      let dateStr = ''
      const hours = d.diff(today, 'hour')
      if (hours < 24 && hours >= 0) {
        dateStr = '今天'
        if (this.showTime) {
          const minutes = dayjs().diff(d, 'minutes')
          if (minutes < 5) {
            return '刚刚'
          } else if (minutes < 30) {
            return `${minutes}分钟前`
          } else if (minutes < 60) {
            return '半小时前'
          } else {
            return Math.floor(minutes / 60) + '小时前'
          }
        }
      } else if (hours < 0 && hours >= -24) {
        dateStr = '昨天'
      } else if (hours < -24 && hours >= -48) {
        dateStr = '前天'
      } else {
        dateStr = d.format(sameYear ? 'MM月DD日' : 'YY年MM月DD日')
      }
      return this.showTime ? dateStr + ' ' + d.format('HH:mm') : dateStr
    }
  }
}
</script>
