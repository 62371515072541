<template>
  <loading-content :loading="loading" :error="error">
    <el-card v-if="info">
      <!-- <h2 class="name">{{info.table.name}}<round :round="info.round.round" :type="info.round.type" /></h2>
      <div v-if="!info.report && info.draft" style="text-align:right; color: #888; font-size: 12px; margin-bottom: 10px;">
        以下数据为您在<date-time :time="info.draft.updateAt || info.draft.createAt" showTime />，临时保存的数据
      </div> -->
      <report-table :table="table" ref="table" :data="data" :round="info.round" :report="info.report" :draft="info.draft" />
      <!-- <div style="margin-top: 30px;">
        <div v-if="info.report && info.report.updateAt && info.report.updateAt !== info.report.createAt" class="info">
          <span>首次填报时间：</span><date-time :time="info.report.createAt" showTime></date-time>
          <span style="margin-left: 10px">上次修改时间：</span><date-time :time="info.report.updateAt" showTime></date-time>
        </div>
        <div v-else-if="info.report && info.report.createAt" class="info">
          <span>填报时间：</span><date-time :time="info.report.createAt" showTime></date-time>
        </div>
        <div style="text-align:center">
          <el-button size="medium" icon="el-icon-back" @click="goBack">返 回</el-button>
          <el-tooltip content="暂存不会上报，不验证数据错误，下次填报时自动导入" placement="top" v-if="!info.report">
            <el-button size="medium"  @click="doDraft"  style="width: 110px; ">暂 存</el-button>
          </el-tooltip>
          <el-button size="medium" :loading="posting" @click="doReport" type="primary" style="width: 110px; ">上 报</el-button>
        </div>
      </div> -->
    </el-card>
  </loading-content>
</template>
<script>
import { mapActions } from 'vuex'
import request from '../request'

export default {
  data () {
    return {
      info: null,
      loading: true,
      error: null,
      tableId: 0,
      roundId: 0,
      reportId: 0,
      posting: false,
      table: null,
      data: {}
    }
  },
  mounted () {
    const { tableId, roundId, reportId } = this.$route.query
    this.tableId = parseInt(tableId)
    this.roundId = parseInt(roundId)
    this.reportId = parseInt(reportId)
    request('/reports/data', { params: { tableId, roundId, reportId } })
      .then(res => {
        this.error = null
        this.info = res
        this.data = res.report ? res.report.data : res.draft ? res.draft.data : {}
        this.table = res.table
        this.loading = false
      })
      .catch(err => {
        this.loading = false
        this.error = err
      })
  },
  methods: {
    ...mapActions('myProgress', ['postReport']),
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0 || rowIndex === 3) {
          return { rowspan: 3, colspan: 1 }
        } else {
          return [0, 0]
        }
      }
    },
    goBack () {
      this.$router.back()
    },
    doDraft () {
      const data = {
        tableId: this.tableId, roundId: this.roundId, data: this.$refs.table.form
      }
      console.log(data)
      this.$request.post('/reports/draft', data)
        .then(() => this.$message({ type: 'success', message: '暂存成功' }))
    },
    doReport () {
      this.$refs.table.$refs.form.validate((valid) => {
        if (!valid) return this.$message({ type: 'warning', message: '有不符要求的填报项，请查看标红项' })
        // 额外的验证
        if (this.$refs.table.extraValidate) {
          const message = this.$refs.table.extraValidate()
          if (message) {
            this.$message({ type: 'warning', message })
            return
          }
        }
        this.posting = true
        const data = this.$refs.table.form
        this.postReport({ tableId: this.tableId, roundId: this.roundId, data })
          .then(() => {
            this.$message({ type: 'success', message: '上报成功', showClose: true })
            setTimeout(() => {
              this.$router.back()
            }, 1000)
          })
          .finally(() => {
            this.posting = false
          })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    font-size: 18px; text-align: center; margin: 10px 0 25px;
    .round { margin-left: 10px; color: #999; font-size: 13px;}
  }
  .info { color: #999; text-align: right; font-size: 12px; margin-bottom: 12px; }
</style>
