import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Main from '../layouts/Main.vue'
import Account from '../layouts/Account.vue'
import Empty from '../layouts/Empty.vue'
import Page404 from '../views/Page404.vue'
import Login from '../views/account/Login.vue'
import Register from '../views/account/Register.vue'
import ResetPassword from '../views/account/ResetPassword.vue'
import Change from '../views/account/Change.vue'
import IProfile from '../views/i/Profile.vue'
import IPassword from '../views/i/Password.vue'
import Records from '../views/records/Records.vue'
import Report from '../views/Report.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/home',
    meta: { title: '首页' },
    children: [
      {
        path: 'home',
        name: 'home',
        meta: { title: '首页' },
        component: () => {
          return !store.state.account.user
            ? Empty
            : store.state.account.user.role === 'sample'
              ? import(/* webpackChunkName: "sample" */ '../views/HomeSample.vue')
              : import(/* webpackChunkName: "admin" */ '../views/Home.vue')
        }
      },
      {
        path: 'info/zhidu',
        name: 'zhidu',
        meta: { title: '报表制度' },
        component: () => import(/* webpackChunkName: "sample" */ '../views/info/zhidu.vue')
      },
      {
        path: 'info/zhibiao',
        name: 'zhibiao',
        meta: { title: '指标解释' },
        component: () => import(/* webpackChunkName: "sample" */ '../views/info/zhibiao.vue')
      },
      {
        path: 'records',
        name: 'records',
        meta: { title: '填报记录' },
        component: Records
      },
      {
        path: 'records/samples',
        name: 'records_samples',
        meta: { title: '企业填报统计' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/records/Samples.vue')
      },
      {
        path: 'records/data',
        name: 'records_data',
        meta: { title: '填报数据查询' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/records/Data.vue')
      },
      {
        path: 'report',
        name: 'report',
        meta: { title: '报表填报' },
        component: Report
      },
      {
        path: 'index_system',
        name: 'index_system',
        redirect: 'index_system/system',
        meta: { title: '指标计算' },
        component: Empty,
        children: [{
          path: 'system',
          meta: { title: '指标体系' },
          component: () => import(/* webpackChunkName: "admin" */ '../views/index_system/System.vue')
        }, {
          path: 'report',
          meta: { title: '指标填报' },
          component: () => import(/* webpackChunkName: "admin" */ '../views/index_system/Report.vue')
        }, {
          path: 'records',
          meta: { title: '指标数据查询' },
          component: () => import(/* webpackChunkName: "admin" */ '../views/index_system/Records.vue')
        }, {
          path: 'calculate',
          meta: { title: '指标计算' },
          component: () => import(/* webpackChunkName: "admin" */ '../views/index_system/Calculate.vue')
        }]
      },
      {
        path: 'indexes',
        name: 'indexes',
        meta: { title: '指数查询' },
        component: Empty,
        redirect: '/indexes/chart',
        children: [
          {
            path: 'chart',
            name: 'chart',
            meta: { title: '指数图表' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/indexes/Indexes.vue')
          },
          {
            path: 'data',
            name: 'data',
            meta: { title: '指数数据' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/indexes/Data.vue')
          },
          {
            path: 'jingqi',
            name: 'jingqi',
            meta: { title: '景气指数' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/indexes/Jingqi.vue')
          }
        ]
      },
      {
        path: 'samples',
        name: 'samples',
        meta: { title: '样本管理' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/Samples.vue')
      },
      {
        path: 'movies',
        name: 'movies',
        meta: { title: '影视管理' },
        component: Empty,
        redirect: '/movies/list',
        children: [
          {
            path: 'list',
            name: 'list',
            meta: { title: '所有影视' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/movies/List.vue')
          },
          {
            path: 'create',
            name: 'create',
            meta: { title: '添加影视' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/movies/Create.vue')
          },
          {
            path: 'company',
            name: 'company',
            meta: { title: '影视公司' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/movies/Company.vue')
          },
          {
            path: 'data',
            name: 'data',
            meta: { title: '爬取数据' },
            component: () => import(/* webpackChunkName: "admin" */ '../views/movies/Data.vue')
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        meta: { title: '用户管理' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/Users.vue')
      },
      {
        path: 'rounds',
        name: 'rounds',
        meta: { title: '期次管理' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/Rounds.vue')
      },
      {
        path: 'progress',
        name: 'progress',
        meta: { title: '填报进度' },
        component: () => import(/* webpackChunkName: "admin" */ '../views/Progress.vue')
      },
      {
        path: 'i',
        name: 'i',
        meta: { title: '我的' },
        component: Empty,
        redirect: '/i/profile',
        children: [
          {
            path: 'profile',
            name: 'i_profile',
            component: IProfile,
            meta: { title: '修改资料' }
          },
          {
            path: 'password',
            name: 'i_password',
            component: IPassword,
            meta: { title: '修改密码' }
          }
        ]
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Account,
    children: [{
      path: 'login',
      name: 'login',
      meta: { title: '登录' },
      component: Login
    }, {
      path: 'register',
      name: 'register',
      meta: { title: '注册账号' },
      component: Register
    }, {
      path: 'reset_password',
      name: 'resetPassword',
      meta: { title: '忘记密码' },
      component: ResetPassword
    }, {
      path: 'change',
      name: 'change',
      meta: { title: '变更负责人' },
      component: Change

    }]
  },
  {
    path: '/crawler',
    component: () => import(/* webpackChunkName: "crawler" */ '../views/crawler/Index.vue')
  },
  {
    // 会匹配所有路径
    path: '*',
    name: 'page404',
    meta: { title: '页面没有找到' },
    component: Page404
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const path = to.path
  if (path.indexOf('/account') >= 0) return next()
  if (!store.state.account.user) {
    store.dispatch('account/getCurrentUser')
      .then((user) => {
        user ? next() : next('/account/login?redirect=' + encodeURIComponent(to.fullPath))
      })
      .catch(next)
  } else {
    next()
  }
})
router.afterEach((to) => {
  const m = to.matched
  if (m.length > 0) {
    const title = m[m.length - 1].meta.title
    document.title = title ? title + '|' + siteTitle : siteTitle
  }
})

export default router
