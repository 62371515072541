<template>
  <div>
    <h3>{{table.name}}</h3>
    <div class="round-info">
      <round :round="round.round" :type="round.type" />
      <span v-if="account.role === 'admin'" style="margin-left: 12px;">
        <el-link @click="$showSample(sample.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{sample.name}}</el-link>
      </span>
    </div>
    <div class="head-info">
      <div>
        <p v-if="!report && draft">
          以下数据为您在<date-time :time="draft.updateAt || draft.createAt" showTime />，临时保存的数据
        </p>
        <template v-if="report && report.updateAt && report.updateAt !== report.createAt">
          <p><span>首次填报时间：</span><date-time :time="report.createAt" showTime></date-time></p>
          <p><span>上次修改时间：</span><date-time :time="report.updateAt" showTime></date-time></p>
        </template>
        <p v-else-if="report && report.createAt">
          <span>填报时间：</span><date-time :time="report.createAt" showTime></date-time>
        </p>
        <p v-if="user"><span>填报用户：</span><span>{{user.name}}</span></p>
        <p v-if="user"><span>用户电话：</span><span>{{user.phone}}</span></p>
      </div>
      <div v-if="!reporting" class="info">
        <p><review-status :status="report.reviewStatus" :comment="report.reviewComment"></review-status></p>
        <p class="act" v-if="account.role === 'admin'" style="margin-top: 10px;">
          <el-button v-if="report.reviewStatus !== 0" size="mini" type="warning" round @click="postReview(0)">重置为未审核</el-button>
          <el-button v-if="report.reviewStatus !== 1" size="mini" type="success" round @click="postReview(1)">通过审核</el-button>
          <el-button v-if="report.reviewStatus !== -1" size="mini" type="warning" round @click="postReview(-1)">不通过审核</el-button>
        </p>
      </div>
      <div class="txt">
        <p v-for="info in table.info" :key="info">{{info}}</p>
      </div>
    </div>
    <el-form ref="form" :model="form" :inline-message="true" @submit.native.prevent="doReport" size="mini" label-width="0px" label-position="left" hide-required-asterisk>
      <el-table :data="table.rows" :span-method="calcSpan" border>
        <el-table-column v-for="(header, index) in table.headers" :key="header.id" :label="header.title" :width="header.width" :fixed="header.fixed">
          <template slot-scope="scope">
            <span v-if="index < scope.row.parents.length" :class="{ marked: scope.row.parents[index].mark }" :style="{ paddingLeft: scope.row.parents[index].indent ? (scope.row.parents[index].indent * 30) + 'px' : 0 }">
              {{scope.row.parents[index].name}}
              <el-popover
                v-if="scope.row.parents[index].notes"
                placement="top"
                width="200"
                trigger="hover"
                :content="scope.row.parents[index].notes">
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </span>
            <span v-else :style="{ paddingLeft: scope.row.input.indent ? (scope.row.input.indent * 30) + 'px' : 0 }">
              {{scope.row.input.name}}
            </span>
          </template>
        </el-table-column>
        <template v-if="table.matrix.length === 0">
          <el-table-column label="填报" width="auto">
            <template slot-scope="scope">
              <report-table-input :form="form" :code="`${scope.row.input.id}`" :input="scope.row.input" :reporting="reporting" :extra-rule="extraRules[scope.row.input.id]"/>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column v-for="m1 in table.matrix[0]" :label="m1.name" :key="m1.code">
            <el-table-column v-for="m2 in table.matrix[1]" :label="m2.name" :key="m2.code" width="180px">
              <template slot-scope="scope">
                <report-table-input :form="form" :code="`${scope.row.input.id}_${m1.code}_${m2.code}`" :input="scope.row.input" :reporting="reporting" />
              </template>
            </el-table-column>
          </el-table-column>
        </template>
      </el-table>
      <dl class="report-note" v-if="reporting && table.notes && table.notes.length > 0">
        <dt>说明：</dt>
        <dd v-for="note in table.notes" :key="note">{{note}}</dd>
      </dl>
      <div style="margin-top: 30px; text-align:center" v-if="reporting">
        <el-button size="medium" icon="el-icon-back" @click="goBack">返 回</el-button>
        <el-tooltip content="暂存不会上报，不验证数据错误，下次填报时自动导入" placement="top" v-if="!report">
          <el-button size="medium"  @click="doDraft"  style="width: 110px; ">暂 存</el-button>
        </el-tooltip>
        <el-button size="medium" :loading="posting" native-type="submit" type="primary" style="width: 110px; ">上 报</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import np from 'number-precision'

export default {
  name: 'ReportTable',
  data () {
    return {
      posting: false,
      form: {},
      extraRules: {}
    }
  },
  props: {
    report: { type: Object, default: null },
    draft: { type: Object, default: null },
    table: Object,
    round: Object,
    user: Object,
    sample: Object,
    reporting: { type: Boolean, default: true }
  },
  computed: {
    ...mapState('account', { account: 'user' })
  },
  watch: {
    report (v) {
      console.log('report watch')
      if (v) {
        this.form = Object.assign({}, this.report.data)
      } else {
        this.form = {}
      }
    }
  },
  methods: {
    ...mapActions('myProgress', ['postReport']),
    calcSpan (info) {
      const { row, columnIndex } = info
      const len = row.parents.length
      if (columnIndex >= len) return [1, 1]
      return [row.parents[columnIndex].rowspan, 1]
    },
    goBack () {
      this.$router.back()
    },
    doDraft () {
      const data = {
        tableId: this.table.id, roundId: this.round.id, data: this.form
      }
      console.log(data)
      this.$request.post('/reports/draft', data)
        .then(() => this.$message({ type: 'success', message: '暂存成功' }))
    },
    doReport () {
      this.$refs.form.validate((valid) => {
        if (!valid) return this.$message({ type: 'warning', message: '有不符要求的填报项，请查看标红项' })
        // 额外的验证
        // if (this.$refs.table.extraValidate) {
        //   const message = this.$refs.table.extraValidate()
        //   if (message) {
        //     this.$message({ type: 'warning', message })
        //     return
        //   }
        // }
        this.posting = true
        // const data = this.$refs.table.form
        this.postReport({ tableId: this.table.id, roundId: this.round.id, data: this.form })
          .then(() => {
            this.$message({ type: 'success', message: '上报成功', showClose: true })
            setTimeout(() => {
              this.$router.back()
            }, 1000)
          })
          .finally(() => {
            this.posting = false
          })
      })
    },
    postReview (status) {
      this.$emit('postReview', status)
    }
  },
  created () {
    if (this.report) {
      this.form = Object.assign({}, this.report.data)
    } else if (this.draft) {
      this.form = Object.assign({}, this.draft.data)
    }
    if (this.reporting && this.table.id === 1) {
      this.extraRules = {
        165: {
          validator: (rule, value, cb) => {
            const sum = np.plus(this.form['166'] || 0, this.form['167'] || 0, this.form['168'] || 0, this.form['175'] || 0)
            if (value < sum) return cb(new Error('02应该大于等于03+04+05+06'))
            cb()
          },
          trigger: 'blur'
        },
        169: {
          validator: (rule, value, cb) => {
            const sum = np.plus(this.form['170'] || 0, this.form['171'] || 0, this.form['172'] || 0, this.form['176'] || 0)
            if (value < sum) return cb(new Error('07应该大于等于08+09+10+11'))
            cb()
          },
          trigger: 'blur'
        },
        21: {
          validator: (rule, value, cb) => {
            const sum = np.plus(this.form['22'] || 0, this.form['23'] || 0, this.form['24'] || 0, this.form['177'] || 0)
            if (value < sum) return cb(new Error('19应该大于等于20+21+22+23'))
            cb()
          },
          trigger: 'blur'
        },
        25: {
          validator: (rule, value, cb) => {
            const sum = np.plus(this.form['26'] || 0, this.form['27'] || 0, this.form['28'] || 0, this.form['178'] || 0)
            if (value < sum) return cb(new Error('24应该大于等于25+26+27+28'))
            cb()
          },
          trigger: 'blur'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  h3 {
    font-size: 18px; text-align: center; margin: 10px 0 12px;
  }
  .round-info { text-align: center; color: #606266;  margin-bottom: 10px; }
  .head-info {
    display: flex; margin-bottom: 10px; color: #606266;
    div { flex: 1; p { margin-bottom: 6px; } }
    .info { text-align: center; }
    .txt { text-align: right; }
  }
  ::v-deep {
    .el-form-item { margin-bottom: 0 !important; }
    .el-radio { display: block; }
    .measure { padding: 0 12px; }
  }
  .marked { background-color: #ffff00; }

</style>
