<style lang="scss" scoped>
  .c {
     padding: 10px 40px 0px 20px;
  }
  .title {
    font-size: 20px; padding: 0 0 30px 80px;
  }
</style>
<template>
<div class="c">
  <div class="title">忘记密码</div>
  <el-form ref="form" :model="form" :rules="rules" hide-required-asterisk  label-width="80px" @submit.native.prevent="doRest">
    <el-form-item label="手机号码" prop="phone">
      <el-input v-model="form.phone" clearable placeholder="请输入绑定的手机号码"></el-input>
    </el-form-item>
    <el-form-item label="验证码" prop="code">
      <el-input v-model="form.code" placeholder="验证码" style="width: 110px; margin-right: 8px;"></el-input>
      <code-button :phone="form.phone" />
    </el-form-item>
    <el-form-item label="设置密码" prop="password">
      <el-input placeholder="请输入新的登录密码" v-model="form.password" show-password></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" style="width: 100%;" size="medium">重置密码</el-button>
      <div style="text-align: right;">
        <el-link href="#/account/login">返回登录</el-link>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import request from '../../request'
export default {
  data () {
    return {
      form: { phone: '', password: '', code: '' },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新的登录密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      posting: false
    }
  },
  methods: {
    doRest () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        request.post('/account/reset_password', { ...this.form })
          .then(() => {
            this.posting = false
            this.$message({ type: 'success', message: '重置成功，请重新登录', showClose: true })
            setTimeout(() => {
              this.$router.replace('/account/login')
            }, 1500)
          })
          .catch(() => {
            this.posting = false
          })
      })
    }
  }
}
</script>
