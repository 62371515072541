<template>
  <el-upload
      action="/api/files/business_license"
      :show-file-list="false"
      :on-success="handleImgSuccess"
      :on-error="handleImgError"
      :before-upload="beforeImgUpload"
      accept="image/*"
    >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">
      <div v-if="url">
        <el-image :preview-src-list="[url + '!bl_p']" :src="url + '!bl_t'" />
      </div>
      请上传营业执照扫描件，且不超过2MB
    </div>
  </el-upload>
</template>

<script>

export default {
  name: 'imgUploader',
  model: {
    prop: 'url',
    event: 'uploaded'
  },
  props: {
    url: String
  },
  // data () {
  //   return { url: '' }
  // },
  // props: {
  //   img: { type: String, required: false }
  // },
  methods: {
    handleImgSuccess (res, file) {
      // console.log(res)
      // console.log(file)
      // this.form.yyzz = res.url
      this.$message.success('上传成功')
      // this.url = res.url
      this.$emit('uploaded', res.url)
    },
    handleImgError () {
      this.$message.error('上传失败')
    },
    beforeImgUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    }
  }
}
</script>
