<template>
  <div>
    <div style="margin-bottom: 10px;">
      <span>抓取状态：</span>
      <el-tag v-if="sample.maoyanStatus === 1" type="success" size="small">抓取</el-tag>
      <el-tag v-else-if="sample.maoyanStatus === -1" type="warning" size="small">不需抓取</el-tag>
      <span style="margin-left: 20px;">绑定猫眼ID：</span>
      <span>{{sample.maoyanId === -1 ? '不需要绑定': sample.maoyanId === 0 ? '未绑定' : sample.maoyanId}}</span>
      <a :href="`https://piaofang.maoyan.com/companydetail/${sample.maoyanId}`" v-if="sample.maoyanId > 0" target="_blank" style="margin-left: 8px;">猫眼页面</a>
    </div>
    <masonry :cols="{default: 2}" :gutter="16" v-if="list.length > 0">
      <movie :movie="movie" style="margin-bottom: 20px" v-for="movie in list" :key="movie.id" />
    </masonry>
    <div v-else>
      <div style="margin: 40px auto; text-align: center; color: #c9c9c9; ">暂未有相关影视作品</div>
    </div>
    <el-pagination
      @current-change="changeCurrent"
      @size-change="changeSize"
      :current-page.sync="page.current"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="page.size"
      layout="sizes, prev, pager, next, total"
      :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import Movie from './Movie'
import listPageQuery from '../mixins/list_page_query'
export default {
  components: { Movie },
  mixins: [listPageQuery],
  name: 'SampleMovies',
  props: {
    sample: { type: Object, required: true }
  },
  watch: {
    sample (v) {
      if (v) {
        this.fetch()
      }
    }
  },
  methods: {
    url () {
      return '/movies?sampleId=' + this.sample.id
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style scoped>
a { color:#333; }
</style>
