<template>
  <div v-loading="loading" class="loading">
    <div v-if="error" class="error">
      <embed :src="error.status === 405 ? svg405 : svg" type="image/svg+xml" class="img" />
      <p class="message">{{error.message}}</p>
      <div class="btn">
        <el-button v-if="error.status === 405" type="primary" round icon="el-icon-edit" @click="goRegister">去完善企业信息</el-button>
        <el-button v-else type="primary" round icon="el-icon-refresh" @click="reload">点此刷新</el-button>
      </div>
    </div>
    <slot v-else/>
  </div>
</template>

<script>
import svg from '../assets/error.svg'
import svg405 from '../assets/405.svg'

export default {
  name: 'LoadingContent',
  data () {
    return {
      svg: svg,
      svg405: svg405
    }
  },
  props: {
    loading: { type: Boolean, default: true },
    error: { type: Object, default: null },
    customRetry: { type: Boolean, default: false }
  },
  computed: {
    // style () {
    //   return this.error || this.loading ? { height: this.height + 'px' } : null
    // }
  },
  methods: {
    reload () {
      if (!this.customRetry) {
        window.location.reload(true)
      } else {
        this.$emit('retry')
      }
    },
    goRegister () {
      this.$router.push('/account/register')
    }
  }
}
</script>
<style lang="scss" scoped>
  .loading { height: 100%;}
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    flex-direction: column;
  }
  .img { width: 250px; margin-bottom: 20px; }
  .message { color: #999; margin-bottom: 20px; }
</style>
