import request from '@/request'

export default {
  namespaced: true,
  state: {
    query: { current: 1, size: 20 },
    params: {},
    list: [],
    error: null,
    page: {
      total: 0,
      size: 20,
      current: 1
    },
    loading: true,
    opening: [],
    openingError: null,
    openingLoading: false
  },
  mutations: {
    changeLoading (state, loading) {
      state.loading = loading
    },
    loadSuccess (state, payload) {
      const { list, current, size, total } = payload
      state.list = list
      state.page = { total, size: size, current }
      state.error = null
    },
    loadError (state, error) {
      state.error = error
    },
    changeQuery (state, qs) {
      state.query = { ...state.query, ...qs }
    },
    loadOpeningSuccess (state, payload) {
      state.opening = payload
    },
    loadOpeningError (state, error) {
      state.openingError = error
    },
    changeOpeningLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    search ({ commit, state }, query) {
      return new Promise(function (resolve, reject) {
        commit('changeLoading', true)
        request.get('/rounds', { params: { ...state.query, ...query } })
          .then((res) => {
            commit('loadSuccess', res)
            commit('changeQuery', query)
            resolve(res)
          })
          .catch((err) => {
            commit('loadError', err)
            reject(err)
          })
          .then(() => {
            commit('changeLoading', false)
          })
      })
    },
    subQuery ({ state, dispatch }) {
      return dispatch('search', { ...state.params, current: 1 })
    },
    reGet ({ state, dispatch }) {
      return dispatch('search', state.query)
    },
    refresh ({ state, dispatch }) {
      return dispatch('search', state.query)
    },
    changeCurrent ({ dispatch }, current) {
      // commit('changeQuery', { current })
      dispatch('search', { current })
    },
    changeSize ({ dispatch }, size) {
      // commit('changeQuery', { size })
      dispatch('search', { current: 1, size })
    },
    fetchOpening ({ commit, state }, query) {
      return new Promise(function (resolve, reject) {
        commit('changeOpeningLoading', true)
        request.get('/rounds/opening', { params: query })
          .then((res) => {
            commit('loadOpeningSuccess', res)
            resolve(res)
          })
          .catch((err) => {
            commit('loadOpeningSuccessError', err)
            reject(err)
          })
          .then(() => {
            commit('changeOpeningLoading', false)
          })
      })
    }
  }
}
