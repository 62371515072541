import Vue from 'vue'
import store from '../store'
import Sample from '../components/Sample.vue'

// 定义插件对象
const Wt = {}
// vue的install方法，用于定义vue插件
Wt.install = function (Vue, { store }) {
  const View = Vue.extend(Sample)
  // 在Vue的原型上添加实例方法，以全局调用
  let view = null
  Vue.prototype.$showSample = function (sampleId) {
    if (!view) {
      view = new View()
      view.$store = store
      document.body.appendChild(view.$mount().$el)
    }
    view.sampleId = sampleId
    view.visible = true
  }
}
Vue.use(Wt, { store })
// export default Wt
