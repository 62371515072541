import request from '../../request'
import listPage from '../list_page'

export default listPage('/progress', {
  actions: {
    postReport ({ dispatch }, data) {
      return new Promise(function (resolve, reject) {
        request.post('/reports', data)
          .then(() => {
            resolve()
            dispatch('search')
          })
          .catch(reject)
      })
    }
  }
})
