<template>
  <el-table-column :label="info.measure ? `${info.name}\n(${info.measure})`: info.name" :column-key="'x' + info.id">
    <nested-columns v-for="c in info.children" :key="c.id" :info="c" :matrix="matrix"></nested-columns>
    <template v-if="!info.children && matrix.length > 0">
      <el-table-column v-for="m1 in matrix[0]" :key="m1.id" :label="m1.name" :column-key="info.id + '_' + m1.id">
        <el-table-column v-for="m2 in matrix[1]" :key="m2.id" :label="m2.name" :column-key="info.id + '_' + m2.id">
          <div slot-scope="scope">{{info.options[scope.row.report.data[info.id + '_' + m1.code + '_' + m2.code]]}}</div>
        </el-table-column>
      </el-table-column>
    </template>
    <div v-if="!info.children" slot-scope="scope">{{scope.row.report.data[info.id]}}</div>
  </el-table-column>
</template>

<script>

export default {
  name: 'NestedColumns',
  data () {
    return {
    }
  },
  props: {
    info: { type: Object },
    matrix: { type: Array }
  },
  methods: {
  }
}
</script>
