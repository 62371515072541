<style scoped lang="scss">
::v-deep .el-form { .el-input, .el-button { width: 300px !important; } }
</style>
<template>
  <el-row :gutter="20">
    <el-col :span="user.role === 'sample' ? 14 : 10" v-if="user.role === 'sample'">
      <el-card>
        <div slot="header">企业信息</div>
        <el-form ref="form" hide-required-asterisk :model="form" :rules="rules" @submit.native.prevent="doUpdateSample" label-width="120px">
          <el-form-item label="信用代码" prop="code">
            <el-input v-if="editing" placeholder="请输入企业信用代码"  v-model="form.code" clearable></el-input>
            <div v-else>{{user.sample.code}}</div>
          </el-form-item>
          <el-form-item label="企业名称" prop="name">
            <el-input v-if="editing" placeholder="请输入企业名称"  v-model="form.name" clearable></el-input>
            <div v-else>{{user.sample.name}}</div>
          </el-form-item>
          <el-form-item label="详细地址" prop="xxdz">
            <el-input v-if="editing" v-model="form.xxdz" placeholder="请输入详细地址"></el-input>
            <div v-else>{{user.sample.xxdz}}</div>
          </el-form-item>
          <el-form-item label="财务负责人" prop="fzr">
            <el-input v-if="editing" placeholder="财务负责人姓名"  v-model="form.fzr" clearable></el-input>
            <div v-else>{{user.sample.fzr}}</div>
          </el-form-item>
          <el-form-item label="财务负责人电话" prop="fzrPhone">
            <el-input v-if="editing" placeholder="财务负责人联系电话"  v-model="form.fzrPhone" clearable></el-input>
            <div v-else>{{user.sample.fzrPhone}}</div>
          </el-form-item>
          <el-form-item label="经营业务" prop="yewu">
            <template v-if="editing">
              <el-checkbox-group  v-model="form.yewu">
                <el-checkbox :label="k" v-for="(v, k) in yewu" :key="k">{{v}}</el-checkbox>
              </el-checkbox-group>
              <p style="font-size: 13px; line-height: 22px; color: #666;">指与影视文化产业关联的产业所对应的产品。</p>
              <p style="font-size: 13px; line-height: 22px; color: #666;">关联产业指与影视文化产业关联的产业，如第三产业中的旅游业、餐饮业、交通运输业、文化和娱乐业、计算机服务和软件业、房地产业等，第二产业中的家具、服装、玩偶、食品饮料、家电、图书与影像品、汽车等制造业。</p>
            </template>
            <div v-else-if="user.sample.yewu.length > 0">
              <!-- {{ user.sample.yewu }} -->
              <el-tag size="small" v-for="yw in user.sample.yewu" :key="yw" style="margin-right: 5px;">{{yewu[yw]}}</el-tag>
            </div>
            <div v-else><i class="el-icon-info" style="color: #E6A23C;"></i>请完善经营业务</div>
          </el-form-item>
          <el-form-item>
            <el-button v-if="editing" type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting">保  存</el-button>
            <el-button v-else type="primary" native-type="submit"  style="width: 100%;" size="medium">修 改</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
    <el-col :span="user.role === 'sample' ? 10 : 14">
      <el-card>
        <div slot="header">修改资料</div>
        <el-form ref="form1" hide-required-asterisk :model="form1" :rules="rules1" @submit.native.prevent="doChangeProfile" label-width="80px" style="width: 300px;">
          <el-form-item label="用户名">
            <span>{{user.loginName}}</span>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-if="editing1" placeholder="请输入姓名"  v-model="form1.name" clearable></el-input>
            <span v-else>{{form1.name}}</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting1">{{ editing1 ? '保  存' : '修改资料'}}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import yewu from '../../utils/yewu'

export default {
  data () {
    return {
      form: { },
      editing: false,
      posting: false,
      form1: { name: '' },
      posting1: false,
      yewu,
      rules1: {
        name: [{ required: true, message: '请输入姓名' }]
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称' }],
        xxdz: [{ required: true, message: '请输入详细地址' }],
        code: [
          { required: true, message: '请输入企业信用代码' },
          { pattern: /^[0-9A-Za-z]{18}$/, message: '请输入正确的信用代码', trigger: 'blur' }
        ],
        fzrPhone: [{ pattern: /^[0-9|-]+$/, message: '请输入正确的电话号码，不含空格', trigger: 'blur' }],
        yewu: [{
          required: true, message: '请选择业务'
        }]
      },
      editing1: false,
      editing2: false,
      posting2: false,
      form2: { cardName: '', cardID: '', cardPhone: '', cardNo: '', cardBank: '' },
      rules2: {
        cardName: [{ required: true, message: '请输入持卡人姓名' }],
        cardID: [{ required: true, message: '请输入持卡人身份证号码' }],
        cardPhone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        cardNo: [{ required: true, message: '请输入银行卡卡号' }],
        cardBank: [{ required: true, message: '请输入开户银行详细信息' }]
      }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState(['areas', 'hys', 'xzs'])
  },
  watch: {
    user (v) {
      if (v) {
        this.form1.name = v.name || ''
        this.form2.cardName = v.cardName || ''
        this.form2.cardID = v.cardID || ''
        this.form2.cardPhone = v.cardPhone || ''
        this.form2.cardNo = v.cardNo || ''
        this.form2.cardBank = v.cardBank || ''
      }
    }
  },
  mounted () {
    this.form1.name = this.user.name
    this.form2.cardName = this.user.cardName
    this.form2.cardID = this.user.cardID
    this.form2.cardPhone = this.user.cardPhone
    this.form2.cardNo = this.user.cardNo
    this.form2.cardBank = this.user.cardBank
  },
  methods: {
    ...mapActions('account', ['updateProfile', 'upsertSample']),
    doChangeProfile () {
      if (!this.editing1) {
        this.editing1 = true
        return
      }
      this.$refs.form1.validate((valid) => {
        if (!valid) return
        this.posting1 = true
        this.updateProfile({ ...this.form1 })
          .then(() => {
            this.$message({ type: 'success', message: '修改成功', showClose: true })
            this.editing1 = false
          })
          .finally(() => {
            this.posting1 = false
          })
      })
    },
    doChangeProfile2 () {
      if (!this.editing2) {
        this.editing2 = true
        return
      }
      this.$refs.form2.validate((valid) => {
        if (!valid) return
        this.posting2 = true
        this.updateProfile({ ...this.form2 })
          .then(() => {
            this.$message({ type: 'success', message: '修改成功', showClose: true })
            this.editing2 = false
          })
          .finally(() => {
            this.posting2 = false
          })
      })
    },
    doUpdateSample () {
      if (!this.editing) {
        this.form = Object.assign({}, this.user.sample)
        this.editing = true
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        this.upsertSample({ ...this.form })
          .then(() => {
            this.$message({ type: 'success', message: '更新成功', showClose: true })
            this.editing = false
          })
          .finally(() => {
            this.posting = false
          })
      })
    },
    imgUploaded (url) {
      console.log('imgUploaded', url)
      this.form.yyzz = url
    }
  }
}
</script>
