<template>
  <div>
    <el-card>
      <div slot="header">
        <el-form inline size="small"  @submit.native.prevent="subQuery">
          <el-form-item label="期次">
            <season-picker v-model="params.round" format="yyyy年q季度" value-format="yyyy-MM-dd"/>
          </el-form-item>
          <el-form-item label="报表">
            <el-select v-model="params.tableId" placeholder="所有报表" style="width: 180px">
              <el-option :value="null" label="所有报表" />
              <el-option
                v-for="item in tables"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="account.role !== 'sample'">
            <el-form-item label="区县" v-if="!account.area">
              <el-select v-model="params.area" placeholder="所有区县" style="width: 120px">
                <el-option value="" label="所有区县" />
                <el-option
                  v-for="item in areas"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业名称">
              <el-input placeholder="企业名称" v-model="params.name"></el-input>
            </el-form-item>
          </template>
          <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
        </el-form>
      </div>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column label="期次"  width="120">
          <round slot-scope="scope" :type="scope.row.round.type" :round="scope.row.round.round" />
        </el-table-column>
        <el-table-column prop="table.name" label="报表"></el-table-column>
        <el-table-column v-if="account.role !== 'sample'" label="企业">
          <el-link slot-scope="scope" @click="$showSample(scope.row.sample.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.sample.name}}</el-link>
        </el-table-column>
        <el-table-column
          label="状态"
          width="120">
          <review-status slot-scope="scope" type="label" :status="scope.row.reviewStatus" :comment="scope.row.reviewComment"/>
        </el-table-column>
        <el-table-column label="填报时间" width="180">
          <date-time slot-scope="scope" show-time :time="scope.row.updateAt || scope.row.createAt" />
        </el-table-column>
        <el-table-column label="查看" width="90">
          <el-button slot-scope="scope" type="text" size="medium" icon="el-icon-link" @click="$showReport(scope.row.id)">查看</el-button>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import SeasonPicker from '../../components/SeasonPicker.vue'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {}
  },
  components: {
    SeasonPicker
  },
  computed: {
    ...mapState('records', ['list', 'error', 'loading', 'page', 'query', 'params']),
    ...mapState('account', { account: 'user' }),
    ...mapState('tables', { tables: 'list' }),
    ...mapState(['areas'])
  },
  methods: {
    ...mapActions('records', ['search', 'subQuery', 'changeCurrent', 'changeSize'])
  },
  mounted () {
    this.search()
  }
}
</script>
