import Vue from 'vue'
import store from '../store'
import router from '../router'
import ReportView from '../components/ReportView.vue'

// 定义插件对象
const Wt = {}
// vue的install方法，用于定义vue插件
Wt.install = function (Vue, context) {
  const { store, router } = context
  const View = Vue.extend(ReportView)
  // 在Vue的原型上添加实例方法，以全局调用
  let view = null
  Vue.prototype.$showReport = function (reportId) {
    if (!view) {
      view = new View({ store, router })
      // view.$store = store
      document.body.appendChild(view.$mount().$el)
    }
    view.reportId = reportId
    view.visible = true
  }
}
Vue.use(Wt, { store, router })
// export default Wt
