<template>
  <span class="round-type">{{str}}</span>
</template>

<script>
export default {
  name: 'RoundType',
  props: {
    type: { type: String, required: true }
  },
  computed: {
    str () {
      switch (this.type) {
        case 'month':
          return '月度'
        case 'season':
          return '季度'
        case 'halfyear':
          return '上半度'
        case 'year':
          return '年度'
        default:
          return '未定义'
      }
    }
  }
}
</script>
