<template>
  <router-view/>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  mounted () {
    document.getElementById('page-loading').style.display = 'none'
  }
}
</script>

<style lang="scss">
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
line-height: 1;
}
menu, ol, ul {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
// reset end
html {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color: #333;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body { margin: 0; height: 100%; background-color: #f8f8f8;}
#page-loading { margin: 200px auto; text-align: center; i { font-size: 40px; } }
.el-pager li, .el-pagination button { background: transparent !important; }
.el-pagination { margin-top: 12px; }
.flex-center {
  display: flex;justify-content: center;align-items: center;
}
.el-link { font-weight: 400 !important; }

.report-note {
  margin-top: 12px; line-height: 24px; font-size: 14px;
  dt { font-weight: bold; }
  dd {  color: #606266; margin-bottom: 8px; }
}

.small-nav {
  width: 200px;
  .el-menu-item { height: 48px; line-height: 48px; text-align: right;}
}
.el-card__header .el-form .el-form-item {
  margin-bottom: 4px !important;
}
</style>
