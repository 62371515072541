import request from '@/request'

export default function (url, extend = {}) {
  return {
    namespaced: true,
    state: {
      query: { current: 1, size: 20 },
      params: {},
      list: [],
      error: null,
      page: {
        total: 0,
        size: 20,
        current: 1
      },
      loading: true,
      ...extend.state
    },
    mutations: {
      changeLoading (state, loading) {
        state.loading = loading
      },
      loadSuccess (state, payload) {
        const { list, current, size, total } = payload
        state.list = list
        state.page = { total, size: size, current }
        state.error = null
      },
      loadError (state, error) {
        state.error = error
      },
      changeQuery (state, qs) {
        state.query = { ...state.query, ...qs }
      },
      ...extend.mutations
    },
    actions: {
      search ({ commit, state }, query) {
        return new Promise(function (resolve, reject) {
          commit('changeLoading', true)
          request.get(url, { params: { ...state.query, ...query } })
            .then((res) => {
              commit('loadSuccess', res)
              commit('changeQuery', query)
              resolve()
            })
            .catch((err) => {
              commit('loadError', err)
              reject(err)
            })
            .then(() => {
              commit('changeLoading', false)
            })
        })
      },
      subQuery ({ state, dispatch }) {
        return dispatch('search', { ...state.params, current: 1 })
      },
      reGet ({ state, dispatch }) {
        return dispatch('search', state.query)
      },
      refresh ({ state, dispatch }) {
        return dispatch('search', state.query)
      },
      changeCurrent ({ dispatch }, current) {
        // commit('changeQuery', { current })
        dispatch('search', { current })
      },
      changeSize ({ dispatch }, size) {
        // commit('changeQuery', { size })
        dispatch('search', { current: 1, size })
      },
      ...extend.actions
    }
  }
}
