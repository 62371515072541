import request from '@/request'

export default function (url, extend = {}) {
  return {
    namespaced: true,
    state: {
      query: { current: 1, pageSize: 20 },
      list: [],
      error: null,
      loading: false,
      ...extend.state
    },
    mutations: {
      changeLoading (state, loading) {
        state.loading = loading
      },
      loadSuccess (state, payload) {
        state.list = payload
      },
      loadError (state, error) {
        state.error = error
      },
      changeQuery (state, qs) {
        state.query = qs
      },
      ...extend.mutations
    },
    actions: {
      fetch ({ commit, state }, query) {
        return new Promise(function (resolve, reject) {
          commit('changeLoading', true)
          request.get(url, { params: query })
            .then((res) => {
              commit('loadSuccess', res)
              commit('changeQuery', query)
              resolve()
            })
            .catch((err) => {
              commit('loadError', err)
              reject(err)
            })
            .then(() => {
              commit('changeLoading', false)
            })
        })
      },
      ...extend.actions
    }
  }
}
