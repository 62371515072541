<template>
<div>
  <el-steps :active="active" finish-status="success" simple  style="margin-bottom: 20px; box-sizing: border-box;">
    <el-step title="用户注册" ></el-step>
    <el-step title="企业信息" ></el-step>
    <el-step title="开始填报" ></el-step>
  </el-steps>
  <div style="width: 560px; ">
    <div v-if="active === 0" style="width: 310px; margin: 0 auto;">
      <el-form ref="form1" :model="form"  :rules="rules" hide-required-asterisk label-width="80px" @submit.native.prevent="doRegister">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="form.code" placeholder="验证码" style="width: 110px; margin-right: 8px;"></el-input>
          <code-button :phone="form.phone" />
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting1">立即注册</el-button>
          <div style="text-align: right;">
            <el-link href="#/account/login">已有账号？点此登录</el-link>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="active === 1">
      <el-form class="form2" ref="form2" :inline="true" :model="form2" hide-required-asterisk size="small" label-width="70px" :rules="rules2" @submit.native.prevent="doUpdateInfo">
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="form2.name" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="信用代码" prop="code">
          <el-input v-model="form2.code" placeholder="请输入企业信用代码"></el-input>
        </el-form-item>
        <el-form-item label="区县" prop="area">
          <el-select v-model="form2.area" placeholder="请选择">
            <el-option
              v-for="item in areas"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="法人代表" prop="faren">
          <el-input v-model="form2.faren" placeholder="请输入法人代表"></el-input>
        </el-form-item>
        <el-form-item label="注册资本" prop="zczb">
          <el-input v-model="form2.zczb" placeholder="请输入注册资本（万元）"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="lxdh">
          <el-input v-model="form2.lxdh" placeholder="请输入企业联系电话"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="hy">
          <el-select v-model="form2.hy" placeholder="请选择">
            <el-option
              v-for="item in hys"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业性质" prop="xz">
          <el-select v-model="form2.xz" placeholder="请选择">
            <el-option
              v-for="item in xzs"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="xxdz">
          <el-input v-model="form2.xxdz" type="textarea" placeholder="请输入企业详细地址"></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="jyfw">
          <el-input v-model="form2.jyfw" type="textarea" placeholder="请参照营业执照填写"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="yyzz">
          <img-uploader v-model="form2.yyzz "></img-uploader>
        </el-form-item>
        <div class="btn2">
          <el-button type="primary" native-type="submit"  style="width: 280px;" size="medium" :loading="posting2">保 存</el-button>
          <div class="link">
            <el-link href="#/account/login">已有账号？点此登录</el-link>
          </div>
        </div>
      </el-form>
    </div>
    <div v-if="active === 2" class="finished">
      <div><i class="el-icon-check"></i></div>
      <div class="note">已完成，{{counting}}秒后自动跳转到首页</div>
    </div>
  </div>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ImgUploader from '../../components/ImgUploader.vue'

export default {
  components: { ImgUploader },
  data () {
    return {
      form: { phone: '', name: '', password: '', code: '' },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名' },
          { max: 50, message: '长度不能超过50', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      form2: { name: '', code: '', area: '' },
      rules2: {
        name: [{ required: true, message: '请输入企业名称' }],
        area: [{ required: true, message: '请选择区县' }],
        xxdz: [{ required: true, message: '请输入详细地址' }],
        code: [
          { required: true, message: '请输入企业信用代码' },
          { pattern: /^[0-9A-Za-z]{18}$/, message: '请输入正确的信用代码', trigger: 'blur' }
        ],
        faren: [{ required: true, message: '请输入法人代表' }],
        zczb: [{ required: true, message: '请输入注册资本' }, { pattern: /^[0-9]+(.[0-9]{1,6})?$/, message: '请输入正确的金额', trigger: 'blur' }],
        jyfw: [{ required: true, message: '请输入经营范围' }],
        lxdh: [{ required: true, message: '请输入联系电话' }, { pattern: /^[0-9|-]+$/, message: '请输入正确的电话号码，不含空格', trigger: 'blur' }],
        hy: [{ required: true, message: '请选择行业' }],
        xz: [{ required: true, message: '请选择企业性质' }],
        yyzz: [{ required: true, message: '请上传营业执照' }]
      },
      active: 0,
      posting1: false,
      posting2: false,
      counting: 5
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState(['areas', 'hys', 'xzs'])
  },
  mounted () {
    if (this.user) {
      // this.$refs.form1.resetFields()
      // this.$refs.form2.clearValidate()
      this.active = 1
    }
  },
  methods: {
    ...mapActions('account', ['register', 'upsertSample']),
    // imgUploaded (url) {
    //   this.form2.yyzz = url
    // },
    doRegister () {
      this.$refs.form1.validate((valid) => {
        if (!valid) return
        this.posting1 = true
        this.register({ ...this.form })
          .then(() => {
            this.$message({ type: 'success', message: '注册成功', showClose: true })
            this.active = 1
            this.posting1 = false
          })
          .catch(() => {
            this.posting1 = false
          })
      })
    },
    doUpdateInfo () {
      this.$refs.form2.validate((valid) => {
        if (!valid) return
        this.upsertSample({ ...this.form2 })
          .then(() => {
            this.$message({ type: 'success', message: '保存成功', showClose: true })
            this.active = 2
            this.posting2 = false
            this.counting = 5
            setTimeout(this.countDown, 1000)
          }).catch(() => {
            this.posting2 = false
          })
      })
    },
    countDown () {
      const c = this.counting - 1
      if (c === 0) {
        this.$router.replace('/')
      } else {
        this.counting = c
        setTimeout(this.countDown, 1000)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.finished {
  padding: 50px; margin: 0 auto; text-align: center; color: #999999;
  i { font-size: 40px; color: #67C23A }
  .note { padding: 40px 0;}
}
.form2 {
  .el-input, .el-select, .el-textarea { width: 200px; }
}
.btn2 {
  padding-left:70px; display: flex;
  .link { flex: 1; text-align: right; line-height: 36px;}
}
</style>
