
export default {
  data () {
    return {
      loading: false,
      params: {},
      list: [],
      error: null,
      query: {},
      page: { total: 0, current: 1, size: 20 }
    }
  },
  methods: {
    fetchSuccess (res) {
      const { total, current, list, size } = res
      this.page = { total, current, size }
      this.error = null
      this.loading = false
      this.list = list
    },
    fetchError (err) {
      this.loading = false
      this.error = err
    },
    requestData (query) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const url = typeof this.url === 'function' ? this.url() : this.url
        this.$request(url, { params: query }).then(res => {
          this.fetchSuccess(res)
          this.query = query
          resolve(res)
        }).catch(err => {
          this.fetchError(err)
          reject(err)
        })
      })
    },
    fetch () {
      this.requestData({})
    },
    refresh () {
      this.requestData(this.query)
    },
    subQuery () {
      this.requestData({ ...this.params, current: 1 })
    },
    changeCurrent (current) {
      this.requestData({ ...this.query, current: current })
    },
    changeSize (size) {
      this.requestData({ ...this.query, current: 1, size: size })
    }
  }
}
