import listPage from '../list_page'
import request from '../../request'

export default {
  namespaced: true,
  modules: {
    progress: listPage('/progress'),
    records: listPage('/reports')
  },
  state: {
    error: null,
    loading: true,
    info: null,
    id: null
  },
  mutations: {
    loadSuccess (state, payload) {
      state.error = null
      state.loading = false
      state.info = payload
    },
    loadError (state, err) {
      state.error = err
      state.loading = false
    }
  },
  actions: {
    fetch ({ commit, dispatch }, id) {
      request('/samples/' + id)
        .then(res => {
          commit('loadSuccess', res)
          dispatch('progress/search', { sampleId: id })
          dispatch('records/search', { sampleId: id })
        }).catch(err => {
          commit('loadError', err)
        })
    }
  }
}
