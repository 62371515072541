<template>
  <el-button @click="doSend" :disabled="disabled" style="width:112px;">{{disabled ? '重发(' + counting + '秒后)' : '发送验证码'}}</el-button>
</template>

<script>
import request from '../request'

export default {
  name: 'CodeButton',
  data () {
    return {
      disabled: false,
      counting: 60
    }
  },
  props: {
    phone: { type: String }
  },
  methods: {
    countDown () {
      this.counting = this.counting - 1
      if (this.counting <= 0) {
        this.disabled = false
        return
      }
      setTimeout(this.countDown, 1000)
    },
    doSend () {
      if (!this.phone) return this.$message({ type: 'error', message: '请输入手机号码', showClose: true })
      if (!/^1\d{10}$/.test(this.phone)) return this.$message({ type: 'error', message: '请输入正确的手机号码', showClose: true })
      request.post('/account/code', { phone: this.phone })
        .then(res => {
          this.$message({ type: 'success', message: '发送成功', showClose: true })
          this.counting = 60
          this.disabled = true
          this.countDown()
        })
    }
  }
}
</script>
