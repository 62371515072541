<style lang="scss" scoped>
  ::v-deep  .el-drawer__header { margin-bottom: 10px !important; }
  .info {
    padding: 0 16px;
    h3 { font-size: 16px; padding: 4px 0 12px; font-weight: bold; }
    .item { padding: 5px 0; display: flex; align-items: center; .lb { margin-right: 8px; color: #5c5b5b; } .d { flex: 1; }}
  }
</style>
<template>
  <el-drawer
    :title="info ? info.name : '查看企业'"
    :visible.sync="visible"
    direction="rtl"
    size="50%"
    v-loading="true"
  >
  <loading-content :loading="loading" :error="error" customRetry @retry="requestInfo">
    <div v-if="info" class="info">
      <el-tabs v-model="tab">
        <el-tab-pane label="企业信息" name="info">
          <h3>{{info.name}}</h3>
          <div class="item"><div class="lb">信用代码：</div><div class="d">{{info.code}}</div></div>
          <div class="item"><div class="lb">详细地址：</div><div class="d">{{info.xxdz}}</div></div>
          <div class="item"><div class="lb">联系电话：</div><div class="d">{{info.lxdh}}</div></div>
          <div class="item"><div class="lb">负责人：</div><div class="d">{{info.fzr}}</div></div>
          <div class="item"><div class="lb">负责人电话：</div><div class="d">{{info.fzrPhone}}</div></div>
          <div class="item">
            <div class="lb">经营业务：</div>
            <div class="d">
              <el-tag size="mini" style="margin-right: 4px;" v-for="yw in info.yewu" :key="yw">{{yewu[yw]}}</el-tag>
            </div>
          </div>
          <div class="item">
            <div class="lb">状态：</div>
            <div class="d"><sample-status type="label" :status="info.status" /></div>
          </div>
          <div class="item" v-if="info.comment">
            <div class="lb">备注：</div>
            <div class="d">{{info.comment}}</div>
          </div>
          <el-divider content-position="left">用户</el-divider>
          <div v-for="user in info.users" :key="user.id" style="margin-bottom: 10px;">
            {{user.name}} | {{user.phone}} <span v-if="user.status === -1"> | 已禁用</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="填报进度" name="progress">
          <el-table
            :data="progress.list"
            v-loading="progress.loading"
            style="width: 100%">
            <el-table-column
              label="期次"
              width="120">
              <round slot-scope="scope" :type="scope.row.round.type" :round="scope.row.round.round" />
            </el-table-column>
            <el-table-column prop="table.name" label="报表">
            </el-table-column>
            <el-table-column label="状态" width="110">
              <template slot-scope="scope" >
                <report-status type="label" :report="scope.row.report.status" :review="scope.row.report.reviewStatus" />
              </template>
            </el-table-column>
            <el-table-column label="填报时间" width="130">
              <template slot-scope="scope" v-if="scope.row.report.status !== null">
                <date-time :time="scope.row.report.updateAt || scope.row.report.createAt" show-time style="font-size: 12px; color: #444;"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140">
              <template slot-scope="scope">
                <el-button v-if="scope.row.report.status !== null" type="text" size="medium" icon="el-icon-link" @click="$showReport(scope.row.report.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changeCurrentProgress"
            @size-change="changeSizeProgress"
            :current-page.sync="progress.page.current"
            :page-sizes="[20, 40, 60, 100]"
            :page-size="progress.page.size"
            layout="sizes, prev, pager, next, total"
            :total="progress.page.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="填报记录" name="records">
          <el-table :data="records.list" style="width: 100%" v-loading="records.loading" stripe empty-text="没有数据">
            <el-table-column label="期次"  width="120">
              <round slot-scope="scope" :type="scope.row.round.type" :round="scope.row.round.round" />
            </el-table-column>
            <el-table-column prop="table.name" label="报表"></el-table-column>
            <el-table-column
              label="状态"
              width="120">
              <review-status slot-scope="scope" type="label" :status="scope.row.reviewStatus" />
            </el-table-column>
            <el-table-column label="填报时间" width="180">
              <date-time slot-scope="scope" :time="scope.row.updateAt || scope.row.createAt" />
            </el-table-column>
            <el-table-column label="查看" width="90">
              <el-button slot-scope="scope" type="text" size="medium" icon="el-icon-link" @click="$showReport(scope.row.id)">查看</el-button>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changeCurrentRecords"
            @size-change="changeSizeRecords"
            :current-page.sync="records.page.current"
            :page-sizes="[20, 40, 60, 100]"
            :page-size="records.page.size"
            layout="sizes, prev, pager, next, total"
            :total="records.page.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="影视剧" name="movies">
          <div>
            <sample-movies :sample="info" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </loading-content>
</el-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import yewu from '../utils/yewu'
import SampleMovies from './SampleMovies'

export default {
  name: 'Sample',
  components: { SampleMovies },
  data () {
    return {
      tab: 'info',
      visible: false,
      sampleId: 0,
      yewu
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('sample', ['info', 'error', 'loading', 'progress', 'records'])
  },
  watch: {
    visible (v) {
      if (!v) return
      this.tab = 'info'
      this.requestInfo()
    }
  },
  methods: {
    ...mapActions('sample', ['fetch']),
    ...mapActions('sample/records', { changeCurrentRecords: 'changeCurrent', changeSizeRecords: 'changeSize' }),
    ...mapActions('sample/progress', { changeCurrentProgress: 'changeCurrent', changeSizeProgress: 'changeSize' }),
    url () {
      return '/progress?sampleId=' + this.sampleId
    },
    requestInfo () {
      this.fetch(this.sampleId)
    }
  }
}
</script>
