<template>
  <el-main class="main-error">
    <embed :src="svg" type="image/svg+xml" class="img" />
    <p class="message">页面没有找到或删除</p>
    <div class="btn"><el-button type="primary" round icon="el-icon-refresh" @click="goHome">返回首页</el-button></div>
  </el-main>
</template>
<script>
import svg from '../assets/404.svg'
export default {
  data () {
    return {
      svg
    }
  },
  methods: {
    goHome () {
      this.$router.replace('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.main-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .img { width: 250px; margin-bottom: 20px; }
  .message { color: #999; margin-bottom: 20px; }
</style>
