import request from '@/request'

export default {
  namespaced: true,
  state: {
    system: null,
    error: null
  },
  mutations: {
    load (state, payload) {
      state.system = payload
    },
    loadError (state, error) {
      state.error = error
    }
  },
  actions: {
    fetchIndexSystem ({ commit }) {
      return new Promise(function (resolve, reject) {
        request.get('index/system')
          .then((user) => {
            commit('load', user)
            resolve()
          })
          .catch((err) => {
            commit('loadError', err)
            reject(err)
          })
      })
    }
  }
}
