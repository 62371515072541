import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './plugins/report_view'
import './plugins/show_sample'
import request from './request'
import Round from './components/Round.vue'
import ReportStatus from './components/ReportStatus.vue'
import ReviewStatus from './components/ReviewStatus.vue'
import SampleStatus from './components/SampleStatus.vue'
import LoadingContent from './components/LoadingContent.vue'
import DateTime from './components/DateTime.vue'
import CodeButton from './components/CodeButton.vue'
import RoundType from './components/RoundType.vue'
import ReportTable from './components/ReportTable.vue'
import ReportTableInput from './components/ReportTableInput.vue'
import NestedColumns from './components/NestedColumns'

Vue.prototype.$request = request
Vue.use(VueMasonry)
Vue.component('round', Round)
Vue.component('report-status', ReportStatus)
Vue.component('review-status', ReviewStatus)
Vue.component('sample-status', SampleStatus)
Vue.component('loading-content', LoadingContent)
Vue.component('date-time', DateTime)
Vue.component('code-button', CodeButton)
Vue.component('round-type', RoundType)
Vue.component('report-table', ReportTable)
Vue.component('report-table-input', ReportTableInput)
Vue.component('nested-columns', NestedColumns)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
