import axios from 'axios'
import { Message } from 'element-ui'

const instance = axios.create({
  baseURL: '/api',
  timeout: 20000,
  withCredentials: true
})
instance.interceptors.response.use(response => response.data, (error) => {
  let message = '请求出错'
  let err = { message, status: 500 }
  if (!error.response) {
    message = error.message.indexOf('timeout') >= 0 ? '请求超时' : '网络错误'
    err = { message, status: 0 }
  } else if (error.response.data && typeof error.response.data === 'object') {
    err = error.response.data
  } else if (error.response.data && typeof error.response.data !== 'object') {
    err = { message: '未知错误', status: 500 }
  }
  Message({ type: 'error', message: err.message, showClose: true })
  if (err.status === 401) {
    window.location.href = '#/account/login'
  } else if (err.status === 405) {
    window.location.href = '#/account/register'
  }
  // if (err.status !== 401 && error.config.method.toLowerCase() !== 'get') {
  // notify.error(err.message)
  // }
  // Message({ type: 'error', message: err.message, showClose: true })
  return Promise.reject(err)
})

export default instance
