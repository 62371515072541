<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-card>
        <div slot="header">更换手机号码</div>
        <el-form ref="form2" :model="form2" :rules="rules2" label-width="100px" @submit.native.prevent="doChangePhone" style="width: 320px;">
          <el-form-item label="绑定手机">
            <span>{{user.phone}}</span>
          </el-form-item>
          <el-form-item label="新手机号码" prop="phone">
            <el-input placeholder="请输入新的手机号码" v-model="form2.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="form2.code" placeholder="验证码" style="width: 100px; margin-right: 8px;"></el-input>
            <code-button :phone="form2.phone" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting2">修改手机号码</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card>
        <div slot="header">修改密码</div>
        <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent="doChangePassword" label-width="80px" style="width: 300px;">
          <el-form-item label="用户名">
            <span>{{user.loginName}}</span>
          </el-form-item>
          <el-form-item label="登录密码" prop="oldPassword">
            <el-input placeholder="请输入登录密码"  v-model="form.oldPassword" show-password></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input placeholder="请输入新的登录密码" v-model="form.newPassword" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting">修改密码</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>

</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      form: { oldPassword: '', newPassword: '' },
      form2: { phone: '', code: '' },
      posting: false,
      posting2: false,
      rules: {
        oldPassword: [
          { required: true, message: '请输入登录密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      rules2: {
        phone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    ...mapActions('account', ['changePassword', 'changePhone']),
    doChangePassword () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        this.changePassword({ ...this.form })
          .then(() => {
            this.$message({ type: 'success', message: '修改成功' })
          })
          .finally(() => {
            this.posting = false
          })
      })
    },
    doChangePhone () {
      if (this.user.phone === this.form2.phone) return this.$message({ type: 'error', message: '不能和原绑定手机相同', showClose: true })
      this.$refs.form2.validate((valid) => {
        if (!valid) return
        this.posting2 = true
        this.changePhone({ ...this.form2 })
          .then(() => {
            this.$message({ type: 'success', message: '修改成功', showClose: true })
          })
          .finally(() => {
            this.posting2 = false
          })
      })
    }
  }

}
</script>
